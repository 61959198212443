<template>
  <div class="filter-container">
    <div class="has-margin-bottom">
      <strong
        v-if="!hideFilterHeader"
        class="is-size-5"
      >Search<br /></strong>
      <transition
        name="fade"
        mode="out-in"
      >
        <a
          class="anchor-clear-filters has-text-danger"
          v-if="searchString !== ''"
          key="clear"
          @click="clearFilters()"
        >
          <span class="icon is-small">
            <i class="icon fas fa-times-circle"></i>
          </span>
          <span>Clear search</span></a>
      </transition>
    </div>
    <div class="field-search field has-addons">
      <div class="control control-input">
        <input
          class="input"
          type="text"
          placeholder="What are you looking for?"
          v-on:keyup.enter="search(true)"
          v-model="searchString"
          @input="handleUserQueryInput"
        />
      </div>
      <div class="control">
        <button
          @click="search(true); $googleAnalytics.pushEvent(gaCategory, 'click filter', 'search')"
          class="button is-primary"
        >
          <span class="icon">
            <i class="fas fa-search"></i>
          </span>
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        key="loading"
        class="has-text-centered"
      >
        <img
          src="@/assets/img/s2m-loader-small.gif"
          title="loading"
        />
      </div>
      <div
        v-else-if="!isLoading && suggestedTags.length"
        key="tags"
      >
        <div class="has-text-weight-bold has-margin-top has-margin-bottom">Most common fields of expertise</div>
        <div class="tags">
          <span
            v-for="(tag, tagIndex) in suggestedTags"
            :key="tagIndex"
            class="tag is-outlined"
            :class="{'is-white': !tag.selected, 'is-warning': tag.selected}"
            v-html="$options.filters.stringShortner(tag.Tag, 20)"
            @click="toggleTagSelection({ tagIndex })"
          ></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import checkinProvider from '../../providers/checkin'

export default {
  name: 'CheckinFilter',
  props: {
    gaCategory: {
      type: String,
      default: '',
      required: true
    },
    hideFilterHeader: {
      type: Boolean,
      default: false
    },
    locationId: {
      type: Number,
      default: 0,
      note: 'Allow queries (tag suggestions) to be restricted to a specific location'
    },
    eventId: {
      type: Number,
      default: 0,
      note: 'Allow queries (tag suggestions) to be restricted to a specific event'
    },
    numberOfSuggestions: {
      type: Number,
      default: 20,
      note: 'The number of tag suggestions to show'
    },
    presetSearchString: {
      type: String,
      default: '',
      note: 'Optional pre-filled search string'
    },
    presetSelectedTags: {
      type: Array,
      default: () => {
        return []
      },
      note: 'A pre-selection of tags (tags are ignored if not available)'
    },
    presetSuggestedTags: {
      type: Array,
      default: () => {
        return []
      },
      note: 'Allow the tag suggestion mechanism to be overwritten'
    }
  },
  data() {
    return {
      // Is loading filter data
      isLoading: true,
      // The available list of suggested tags
      suggestedTags: this.$store.getters.getCheckinSearchFilters.suggestedTags || [],
      // The query string
      searchString: this.$store.getters.getCheckinSearchFilters.searchString || '',
      // Indicates whether tag system is active
      tagSystemIsEnabled: true
    }
  },

  /**
   * Obtain the list of suggestion tags upon booting the component
   */
  mounted() {
    // If a preset suggestion list is passed to the component, use that one
    if (this.presetSuggestedTags.length) {
      this.isLoading = false
      this.suggestedTags = this.presetSuggestedTags.map(this.constructTagObj)
    } else if (this.suggestedTags.length) {
      // Get suggetion list from store
      this.isLoading = false
      // this.suggestedTags = this.suggestedTags.map(this.constructTagObj)
    } else {
      // Obtain the tags from the API using the checkinProvider
      let self = this
      this.isLoading = true
      checkinProvider.methods.getCheckinKnowledge(
        this.locationId,
        this.eventId,
        '',
        1,
        this.numberOfSuggestions
      ).then((response) => {
        // Go through all tag objects, set the initial selected state
        // and make the list reactive within the component
        if (response.data && response.data.Results) {
          self.suggestedTags = response.data.Results.map(this.constructTagObj)

          // Update store
          this.updateCheckinFiltersInStore()
        }
      })
        .catch(e => { })
        .then(() => {
          this.isLoading = false
        })
    }
  },

  methods: {
    /**
     * Clear filters
     */
    clearFilters() {
      this.$googleAnalytics.pushEvent(this.gaCategory, 'click filter', 'clear filter')

      this.handleUserQueryInput()

      // Clear search input
      this.searchString = ''

      // Clear store
      this.updateCheckinFiltersInStore()

      // Filter checkins
      this.search(true)
    },
    /**
     * Construct the local tag objects from the obtained
     */
    constructTagObj(tag) {
      if (typeof tag === 'string') {
        tag = { Tag: tag }
      }
      return Object.assign(tag, {
        selected: false
      })
    },

    /**
     * Toggle the tag selection status
     */
    toggleTagSelection({ tagIndex }) {
      this.suggestedTags[tagIndex].selected = !this.suggestedTags[tagIndex].selected

      // Push GA event
      if (this.suggestedTags[tagIndex].selected) {
        this.$googleAnalytics.pushEvent(this.gaCategory, 'click filter', 'tag')
      }

      // Update search term
      this.searchString = this.suggestedTags.reduce((currentValue, tag) => {
        return currentValue + (
          tag.selected ? ' ' + tag.Tag : ''
        )
      }, '').trim()

      // Update store
      this.updateCheckinFiltersInStore()

      this.search()
    },
    /**
     * Generate the style modification object for a tag based on it's selection status
     */
    tagStyle({ selected }) {
      if (selected) {
        return {
          'color': 'red'
        }
      } else {
        return {}
      }
    },
    /**
     * Whenever a user make a change to the query we disable and clear the tag based selection system
     * We re-enable the tag system when the search string change has been processed
     */
    handleUserQueryInput() {
      this.tagSystemIsEnabled = false

      // reset the selection state of all tags
      this.suggestedTags.forEach((tag) => {
        tag.selected = false
      })
      // Re-enable tag system on next tick
      let vm = this
      this.$nextTick(() => {
        vm.tagSystemIsEnabled = true
      })
    },
    /**
     * Emit an event to signal to the application that the user wants to apply a new filter query
     */
    search(closeDrawer = false) {
      // Update store
      this.updateCheckinFiltersInStore()

      // Trigger method in parent
      this.$emit('updateFilterQuery', this.searchString)

      if (closeDrawer) {
        this.$emit('closeDrawer')
      }
    },

    updateCheckinFiltersInStore() {
      this.$store.commit('setCheckinSearchFilters', {
        searchString: this.searchString,
        suggestedTags: this.suggestedTags
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.field-search {
  .control:first-child {
    width: 100%;
  }
}
.anchor-clear-filters {
  align-items: center;
  display: inline-flex;
  margin-top: 3px;
  span.icon {
    margin-right: 5px;
  }
}
.tags {
  justify-content: center;
  .tag {
    cursor: pointer;
    font-size: 12px;
  }
}
</style>

/* global google */

export const CustomMarker = function(latlng, map, args) {
  this.latlng = latlng
  this.args = args
  this.setMap(map)
}

export const initCustomMarker = function() {
  CustomMarker.prototype = new google.maps.OverlayView()
  CustomMarker.prototype.draw = draw
  CustomMarker.prototype.remove = remove
  CustomMarker.prototype.getPosition = getPosition
}

export const removeMarkers = function(arr, mapRef) {
  for (let i = 0; i < arr.length; i++) {
    arr[i].setMap(null)
  }
}

export default {
  CustomMarker,
  initCustomMarker,
  removeMarkers
}

function draw() {
  var self = this
  // TODO: What does this do?
  // if (this.div) {
  //   if (
  //     typeof self.args.removeFirstFromMap !== 'undefined' &&
  //     self.args.removeFirstFromMap === true &&
  //     this.div.dataset.marker_id === self.args.marker_id
  //   ) {
  //     this.remove()
  //   }
  // }

  // TODO: What does this do?
  var div = this.div
  if (!div) {
    div = this.div = document.createElement('div')

    let markerId = 'unknown'
    if (typeof self.args.marker_id !== 'undefined') {
      div.dataset.marker_id = self.args.marker_id
      markerId = self.args.marker_id
    }

    div.className = 'custom_marker'
    div.id = markerId
    div.style.position = 'absolute'
    div.style.cursor = 'pointer'

    if (typeof self.args.zIndex !== 'undefined') {
      div.style.zIndex = self.args.zIndex
    }

    if (typeof self.args.marker_id !== 'undefined') {
      div.dataset.marker_id = self.args.marker_id
    }
    if (typeof self.args.markerData !== 'undefined') {
      div.innerHTML = self.args.markerData
    }

    div.addEventListener('click', function(event) {
      google.maps.event.trigger(self, 'click')
    })

    var panes = this.getPanes()
    if (typeof panes !== 'undefined') {
      panes.overlayImage.appendChild(div)
    }
  }

  // Get default positioning and adjust offset for marker / image size
  var point = this.getProjection().fromLatLngToDivPixel(this.latlng)
  if (point) {
    div.style.left = point.x - 12 + 'px'
    div.style.top = point.y - 12 + 'px'
  }
}

function remove() {
  if (this.div) {
    this.div.parentNode.removeChild(this.div)
    this.div = null
  }
}

function getPosition() {
  return this.latlng
}

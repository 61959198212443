<template>
  <div class="component-maps">
    <drawer-network-checkins
      v-if="showHideNetworkCheckinDrawer"
      :showNetworkCheckinsDrawer="showNetworkCheckinsDrawer"
      :checkins="networkCheckinsNoLatLong"
      @showHideNetworkCheckinDrawer="showHideNetworkCheckinDrawer"
      sectionName="PeopleAndLocations"
    />

    <drawer-checkin-filter
      gaCategory="map"
      :showDrawer="showCheckinFiltersDrawer"
      @showHideCheckinFilterDrawer="showHideCheckinFilterDrawer"
      @updateFilterQuery="filterCheckins"
    />

    <div class="wrapper-map">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="isLoading"
          key="loader"
          class="wrapper-loader"
        >
          <img
            src="@/assets/img/s2m-loader-big.gif"
            title="loader"
            alt="loader"
          />
        </div>
      </transition>
      <div
        v-if="!isTile"
        class="box-checkinFilters box is-hidden-mobile"
      >
        <checkin-filter
          gaCategory="map"
          :hideFilterHeader="true"
          @updateFilterQuery="filterCheckins"
        />
      </div>

      <g-map
        ref="gMap"
        :checkins="mCheckins"
        :mapHeight="mapHeight"
        :isTile="isTile"
        :isLoggedIn="isLoggedIn"
        :locations="locations"
        :hideSearchButton="hideSearchButton"
        @showSelectedLocation="showSelectedLocation"
        @showHideNetworkCheckinDrawer="showHideNetworkCheckinDrawer"
        @showHideCheckinFilterDrawer="showHideCheckinFilterDrawer"
        @showSelectedPublicProfile="showSelectedPublicProfile"
      />

      <div
        v-if="showLegend"
        class="box-legend p-3 has-background-info"
        :class="{'is-mobile': isMobile}"
      >
        <strong class="is-hidden-touch has-margin-right">Locations:</strong>
        <div class="row is-size-7-touch">
          <div>
            <img
              src="/img/map/logo-ms-ws.svg"
              alt="Meeting and co-working locations"
            />
          </div>
          <div>Meeting and co-working</div>
        </div>
        <div class="row is-size-7-touch">
          <div>
            <img
              src="/img/map/logo-ws.svg"
              alt="Co-working locations"
            />
          </div>
          <div>Co-working only</div>
        </div>
        <div class="row is-size-7-touch">
          <div>
            <img
              src="/img/map/logo-silence.svg"
              alt="Co-working locations"
            />
          </div>
          <div>Co-working in the nature</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DrawerNetworkCheckins from '@/components/visualisationMap/DrawerNetworkCheckins'
import DrawerCheckinFilter from '@/components/partial/DrawerCheckinFilter'
import CheckinFilter from '@/components/partial/CheckinFilter'
import Map from '@/components/visualisationMap/Map'
import location from '@/providers/location'
import checkinProvider from '../../providers/checkin'
import match from '@/providers/match'
export default {
  name: 'VisualisationMap',

  components: {
    'checkin-filter': CheckinFilter,
    'drawer-checkin-filter': DrawerCheckinFilter,
    'drawer-network-checkins': DrawerNetworkCheckins,
    'g-map': Map
  },

  props: {
    checkins: {
      type: Array,
      default: function() {
        return []
      }
    },
    isTile: {
      type: Boolean,
      default: false
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    hideSearchButton: {
      type: Boolean,
      default: false
    },
    mapHeight: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoading: true,
      locations: null,
      mCheckins: this.checkins,
      matches: [],
      loadedCheckins: this.checkins,
      showCheckinFiltersDrawer: false,
      showNetworkCheckinsDrawer: false,
      networkCheckinsNoLatLong: [],
      wpid: 0,
      watchLoopCounter: 0,
      loadTimeStamp: 0,
      checkinFilterSearchString:
        this.$store.getters.getCheckinSearchFilters.searchString || '',
      mapUpdate: 0,
      isMobile: false
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    })
  },

  watch: {},

  created() {
    this.getLocations()
    this.getLatLong()
  },

  mounted() {
    window.addEventListener('resize', this.calculateViewport)
    this.calculateViewport()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateViewport)

    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(this.wpid)
    }
    this.wpid = 0
    this.watchLoopCounter = 0
    this.$store.dispatch('clearCheckinSearchFilters')
  },

  methods: {
    calculateViewport() {
      this.isMobile = false
      if (typeof this.$refs.gMap !== 'undefined' && this.$refs.gMap.$el.getBoundingClientRect().width < 550) {
        this.isMobile = true
      }
    },

    /**
     * Filter checkins bases on search query
     */
    filterCheckins(q = '') {
      let searchArr = q.toLowerCase().split(' ')
      let output = []
      let checkins = JSON.parse(JSON.stringify(this.loadedCheckins))
      let removed = []

      if (q === '') {
        this.mCheckins = checkins
        return
      }

      // Search checkins bases on search term
      searchArr.find(searchTerm => {
        checkins.forEach((checkin, i) => {
          let str = (checkin.ProfileName + ' ' + checkin.Tags).toLowerCase()
          if (checkin.locationId) {
            str +=
              ' ' +
              (checkin.LocationName + ' ' + checkin.LocationCity).toLowerCase()
          }
          if (str.match(searchTerm.trim()) !== null) {
            removed.push(i)
            output.push(checkin)
          }
        })

        // Removed founded checkins
        removed.forEach(val => {
          checkins.splice(val, 1)
        })
        removed = []
      })
      this.mCheckins = output
    },

    /**
     * Get user lat long from navigator
     */
    getLatLong() {
      let self = this
      let geoOptions = {
        enableHighAccuracy: true,
        maximumAge: 3000
      }
    },

    /**
     * Get locations
     */
    getLocations() {
      let self = this
      this.isLoading = true

      location.methods.getLocationsFromStorage().then(data => {
        if (data) {
          self.locations = data.Locations
          if (!self.checkins.length) {
            self.getCheckins()
          }
        }

        self.getLocationsFromApi()
      })
    },

    getLocationsFromApi() {
      let self = this
      location.methods
        .getLocationsFromApi(0, 0, '', 0, 0)
        .then(response => {
          self.locations = response.Results
          // location.methods.saveLocationsInStorage(0, 0, 0, '', response.Results)

          if (!self.checkins.length) {
            if (self.isLoading) {
              self.getCheckins()
            }
          } else {
            self.filterCheckins(self.checkinFilterSearchString)
          }
        })
        .catch(e => { })
        .then(() => {
          self.isLoading = false
        })
    },

    /**
     * Get checkins
     */
    getCheckins(q = '') {
      let self = this
      self.isLoading = true

      checkinProvider.methods.getCheckins({
        date: new Date(),
        q,
        cancelPreviousRequest: false
      })
        .then(response => {
          self.mCheckins = self.unDouble(response.data.Results)
          self.loadedCheckins = self.mCheckins
          if (self.checkinFilterSearchString) {
            self.filterCheckins(self.checkinFilterSearchString)
          }
        })
        .catch(e => { })
        .then(() => {
          self.isLoading = false
        })
    },

    /**
     * Get matches bases on search query
     */
    getMatches(q = '') {
      let self = this
      self.isLoading = true

      match.methods.cancelGetMatches()
      match.methods
        .getMatches(q, 0, 0, 1, 200, '0')
        .then(response => {
          self.matches = response.data.Results
          if (!self.matches.length) {
            if (self.$refs.gMap) {
              self.$refs.gMap.removeCheckinMarkers()
            }
          }
          self.checkinFilterSearchString =
            self.$store.getters.getCheckinSearchFilters.searchString
        })
        .catch(e => { })
        .then(() => {
          self.isLoading = false
        })
    },

    /**
     * Undouble checkins
     */
    unDouble(checkins) {
      let doubles = {}
      let itemsToBeRemoved = []
      let newCheckinList = []
      let currentTime = this.$options.filters.timeToMinutes(
        new Date().getTime()
      )

      // Find doubles
      checkins.forEach((checkin, index) => {
        if (doubles[checkin.ProfileId]) {
          doubles[checkin.ProfileId].push(checkin)
        } else {
          doubles[checkin.ProfileId] = [checkin]
        }
      })

      for (let key in doubles) {
        if (doubles[key].length === 1) {
          // Add checkin to new checkin array
          newCheckinList.push(doubles[key][0])
        } else {
          // Figure out which one to take
          doubles[key] = doubles[key].slice().sort((a, b) => {
            // let stepUpOrDown = 0
            if (
              (currentTime > a.StartMinutes &&
                currentTime < a.EndMinutes &&
                a.StartMinutes < b.StartMinutes) ||
              ((currentTime < a.StartMinutes || currentTime > a.EndMinutes) &&
                a.StartMinutes < b.StartMinutes)
            ) {
              return 1
            } else {
              return -1
            }
          })
          newCheckinList.push(this.filterDouble(doubles[key]))
        }
      }
      // console.info(newCheckinList)
      return newCheckinList
    },

    /**
     * Filter double checkins of the same profile
     */
    filterDouble(doubleElements = []) {
      let temp = []
      // Check if checkin is on a location
      temp = this.checkedInOnLocation(doubleElements)

      // Check if checkin is on a event
      if (!temp.length) {
        temp = this.checkedInOnEvent(doubleElements)
      }

      // Check if checkin filled in his working on
      if (!temp.length) {
        temp = this.filledInWorkingOn(doubleElements)
      }
      return temp
    },

    /**
     * Check if checkin is checked in on an event
     */
    checkedInOnEvent(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.EventId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    checkedInOnLocation(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.LocationId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    filledInWorkingOn(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.WorkingOn !== ''
      })
      if (temp.length) {
        return temp[0]
      } else {
        return checkins[0]
      }
    },

    /**
     * Show or hide filter drawer
     */
    showHideCheckinFilterDrawer() {
      this.showCheckinFiltersDrawer = !this.showCheckinFiltersDrawer
    },

    /**
     * Show or Hide netwerk checkins drawer
     */
    showHideNetworkCheckinDrawer(checkinsArr) {
      this.showNetworkCheckinsDrawer = !this.showNetworkCheckinsDrawer
      this.networkCheckinsNoLatLong = checkinsArr
    },

    showSelectedPublicProfile(profile) {
      this.$store.commit('setSelectedPublicProfile', profile)
    },

    /**
     * Store selected locations
     */
    showSelectedLocation(locationId) {
      this.$store.commit('setSelectedLocation', locationId)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-map {
  position: relative;
  .wrapper-loader {
    background: rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .box-checkinFilters {
    background: rgba(#ffffff, 0.9);
    position: absolute;
    left: $gap;
    top: $gap;
    max-width: 320px;
    width: 100%;
    z-index: 1;
  }

  .box-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    .row {
      display: inline-flex;
      align-items: center;
      & + .row {
        margin-left: $gap;
      }
      div:first-child {
        display: flex;
        margin-right: 5px;
      }
      img {
        height: auto;
        width: 28px;
        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }

    &.is-mobile {
      display: block;
      .row {
        display: flex;
        margin-left: 0;
        margin-top: 5px;
        & + .row {
          margin-left: 0;
        }
        div:first-child {
          flex-grow: 0;
          flex-shrink: 0;
          // width: 64px;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <transition name="fade">
      <div
        class="drawer-background"
        v-if="showDrawer"
        @click="hideDrawer"
      ></div>
    </transition>

    <div
      class="drawer left has-background-white"
      :class="{'is-active': showDrawer}"
    >
      <div class="padding header has-text-right">
        <a
          class="close-button"
          @click="hideDrawer"
        >
          <span class="fas fa-times-circle"></span>
          <span>Close</span>
        </a>
      </div>
      <div class="wrapper">
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="showDrawer"
            class="has-padding"
          >
            <checkin-filter
              :gaCategory="gaCategory"
              :hideFilterHeader="hideFilterHeader"
              :numberOfSuggestions="numberOfSuggestions"
              @closeDrawer="hideDrawer()"
              class="box-checkinFilters"
              :presetSuggestedTags="presetSuggestedTags"
              @updateFilterQuery="updateFilterQuery"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import CheckinFilter from '@/components/partial/CheckinFilter'
export default {
  name: 'checkin-filter-drawer',

  components: {
    'checkin-filter': CheckinFilter
  },

  props: {
    gaCategory: {
      type: String,
      default: '',
      required: true
    },
    hideFilterHeader: {
      type: Boolean,
      default: false
    },
    numberOfSuggestions: {
      type: Number,
      default: 20
    },
    presetSuggestedTags: {
      type: Array,
      default: () => {
        return []
      },
      note: 'Allow the tag suggestion mechanism to be overwritten'
    },
    showDrawer: {
      type: Boolean,
      default: false
    }
  },

  data() { return {} },

  methods: {
    filterCheckins(q) {
      this.$emit('filterCheckins', q)
    },
    hideDrawer() {
      // Push GA event
      this.$googleAnalytics.pushEvent('map', 'click filter', 'close filter')

      this.$emit('showHideCheckinFilterDrawer')
    },
    updateFilterQuery(q) {
      this.$emit('updateFilterQuery', q)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <gmap-map
    ref="mapRef"
    :center="{lat: mLatitude, lng: mLongitude}"
    :options="mapOptions()"
    :zoom="mZoomLevel"
    map-type-id="terrain"
    :style="`width: 100%; height: ${mapHeight === '' ? '400px' : mapHeight}`"
  >
  </gmap-map>
</template>

<script>
/* global google */
/* global  CustomMarker */

/**
 * Component description
 * This component will contain:
 * - the map
 * - the location pointers
 * - checkin overlays
 * - filter functionality component
 */

import { mapGetters } from 'vuex'
import { CustomMarker, initCustomMarker, removeMarkers } from './CustomMarker'
import mapStyles from './mapStyles'

export default {
  name: 'mapCheckins',

  props: {
    mapHeight: {
      type: String,
      default: ''
    },
    checkins: {
      type: Array,
      default: function() { return [] }
    },
    locations: {
      type: Array,
      default: function() { return [] }
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    hideSearchButton: {
      type: Boolean,
      default: false
    },
    isTile: {
      type: Boolean,
      default: true
    },
    zoomLevel: {
      type: Number,
      default: 3
    }
  },

  data() {
    return {
      mapRef: this.$refs.mapRef,
      hubLocations: [85, 284, 321, 322, 378, 563, 875],
      iWindow: null,
      geoLocationOnlineInverval: null,
      otherCheckins: [],
      gmarkersNC: [],
      gmarkersNC_NoLatLong: [],
      gmarkersLC: [],
      gmarkersLoc: [],
      gmarkersEvent: [],
      mapIsLoaded: false,
      mLocations: this.locations,
      mZoomLevel: this.zoomLevel
    }
  },
  computed: {
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      latitude: 'getLatitude',
      longitude: 'getLongitude'
    }),

    mLatitude() {
      return this.latitude
    },

    mLongitude() {
      return this.longitude
    }
  },

  watch: {
    checkins(newval, oldval) {
      let self = this
      if (newval !== null) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          self.organizeCheckins(newval)
        })
      }
    },

    locations(newval, oldval) {
      let self = this
      if (newval !== null) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.addLocationsMarkers()
        })
      }
    }
  },

  created() {
  },

  mounted() {
    let self = this
    /**
     * At this point, the child GmapMap has been mounted, but
     * its map has not been initialized.
     * Therefore we need to write mapRef.$mapPromise.then(() => ...)
     */
    this.$refs.mapRef.$mapPromise.then((map) => {
      self.mapRef = self.$refs.mapRef
      initCustomMarker()
      map.panTo({ lat: self.mLatitude, lng: self.mLongitude })

      let controlFilterUI = document.createElement('div')
      let className = 'button button-filter-drawer'
      if (!this.isTile) {
        className += ' ' + 'is-hidden-tablet'
      }
      controlFilterUI.className = className
      controlFilterUI.style.fontSize = '16px'
      controlFilterUI.style.lineHeight = '38px'
      controlFilterUI.style.paddingLeft = '15px'
      controlFilterUI.style.paddingRight = '15px'
      controlFilterUI.innerHTML = '<span class="icon"><i class="fas fa-search"></i></span>'
      controlFilterUI.index = 1
      controlFilterUI.addEventListener('click', function() {
        // Push GA event
        self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'button-ShowFilter', 'PeopleAndLocations | Map')

        // Show filter
        self.$emit('showHideCheckinFilterDrawer')
      })
      if (!self.hideSearchButton) {
        self.$refs.mapRef.$mapObject.controls[google.maps.ControlPosition.TOP_LEFT].push(controlFilterUI)
      }
    })
  },

  beforeDestroy() {
    clearInterval(this.geoLocationOnlineInverval)
  },

  methods: {
    /**
     * Remove markers from map
     */
    removeCheckinMarkers() {
      removeMarkers(this.gmarkersLC, this.$refs.mapRef)
      removeMarkers(this.gmarkersNC, this.$refs.mapRef)
      // removeMarkers(this.gmarkersNC_NoLatLong, this.$refs.mapRef)
    },

    /**
     * The Google Map options (e.g. controles, zoom, styling)
     */
    mapOptions() {
      return {
        minZoom: 2,
        maxZoom: 16,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: mapStyles // Imported from an external file
      }
    },

    createVirtualCheckinStats(checkins = []) {
      let self = this

      if (checkins.length) {
        let controlFilterUI = document.createElement('div')
        let className = 'box has-pointer has-text-centered'
        controlFilterUI.className = className
        controlFilterUI.style.fontSize = '14px'
        controlFilterUI.style.padding = '10px'
        controlFilterUI.style.marginTop = '15px'
        controlFilterUI.style.marginRight = '15px'
        controlFilterUI.innerHTML = `<strong class="title is-3">+${checkins.length}</strong></br><span>Virtual ${checkins.length === 1 ? 'checkin' : 'checkins'}</span>`
        controlFilterUI.index = 1
        controlFilterUI.addEventListener('click', function() {
          // Push GA event
          self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'button-ShowVirtualCheckins', 'PeopleAndLocations | Map')

          // Show network checkins
          self.$emit('showHideNetworkCheckinDrawer', checkins)
        })
        if (!self.hideSearchButton) {
          self.$refs.mapRef.$mapObject.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlFilterUI)
        }
      }
    },

    /**
     * The map icon differs per type of location.
     * @param location
     * @return {{iconImg: String, iconSize: Number, locationType: String}}
     */
    getLocationMapIcon(location) {
      let icon = {
        iconImg: "/img/map/logo-ws.svg",
        iconSize: 18,
        locationType: 'ws',
      }

      // Is key location
      // if (this.hubLocations.indexOf(location.Id) !== -1) {
      //   icon.iconImg = require("@/assets/img/map/48x48_s2mKeyLogo.png");
      //   icon.iconSize = 34;
      //   icon.locationType: 'ws',
      //   return icon;
      // }

      // Is Seats4Silence location
      let locationName = location.Name.toLowerCase();
      if (
        locationName.indexOf("s4s") !== -1 ||
        locationName.indexOf("seats4silence") !== -1 ||
        locationName.indexOf("4 silence") !== -1
      ) {
        icon.iconImg = "/img/map/logo-silence.svg"
        icon.iconSize = 26;
        icon.locationType = 's4s'
        return icon;
      }

      // Is workspace only
      if (typeof location.Meetingtypes !== "undefined") {
        const canMeet = location.Meetingtypes.findIndex(
          (m) => m.MeetingtypeId === 1
        );
        const canCoWorking = location.Meetingtypes.findIndex(
          (m) => m.MeetingtypeId === 2
        );
        if (canMeet !== -1 && canCoWorking !== -1) {
          icon.iconImg = "/img/map/logo-ms-ws.svg"
          icon.iconSize = 22;
          icon.locationType = 'msws'
        }
      }
      return icon;
    },

    /**
     * Organize checkins and split them into 2 groups
     * - location checkins
     * - network checkins
     */
    organizeCheckins(checkins = []) {
      let self = this

      // Clear markers
      removeMarkers(this.gmarkersLC, this.$refs.mapRef)
      removeMarkers(this.gmarkersNC, this.$refs.mapRef)
      removeMarkers(this.gmarkersNC_NoLatLong, this.$refs.mapRef)

      // Reset variables
      this.gmarkersLC = []
      this.gmarkersNC = []
      this.gmarkersNC_NoLatLong = []
      this.otherCheckins = []
      let checkinArr = []
      let checkinEventsArr = []
      let checkinLatLongBasedArr = []
      let onlyNetworkCheckins = true
      let markerBounds = new google.maps.LatLngBounds()
      markerBounds.extend({ lat: this.mLatitude, lng: this.mLongitude }) // <-- Add my current position to bound

      // Split checkins to location array or network array
      checkins.map((checkin, i) => {
        // Set map bounds based on marker lat long
        markerBounds.extend({ lat: checkin.Latitude, lng: checkin.Longitude })

        if (checkin.LocationId > 0) {
          onlyNetworkCheckins = false
          checkinArr = self.addCheckinToArray(checkinArr, `l${checkin.LocationId}`, checkin)
          // } else if (checkin.EventId > 0) {
          //   checkinEventsArr = self.addCheckinToArray(checkinEventsArr, `e${checkin.EventId}`, checkin)
        } else if (checkin.Latitude === 0 && checkin.Latitude === 0) {
          self.otherCheckins.push(checkin)
        } else {
          checkinLatLongBasedArr.push(checkin)
        }
      })

      if (checkins.length && !onlyNetworkCheckins) {
        // Zoom out map to show all markers in bound
        this.$refs.mapRef.fitBounds(markerBounds)
      } else {
        self.mZoomLevel = 3
      }

      // Create location checkin markers and add them on map
      this.addLocationCheckinMarkers(checkinArr)
      this.addNetworkCheckinsMarkers(checkinLatLongBasedArr)
      this.createVirtualCheckinStats(self.otherCheckins.concat(checkinLatLongBasedArr))
    },

    /**
     * Add checkin to array
     * @param arr
     * @param key
     * @param checkin
     * @return array
     */
    addCheckinToArray(arr = [], key = '', checkin = {}) {
      if (typeof arr[key] !== 'undefined') {
        arr[key].push(checkin)
      } else {
        arr[key] = [checkin]
      }
      return arr
    },

    /**
     * Add location markers to map
     */
    addLocationsMarkers() {
      let self = this

      this.locations.map((location, i) => {
        /**
         * Get marker icon
         */
        let markerIcon = self.getLocationMapIcon(location)

        // Create marker
        // Use zIndex boost to position key hubs on top of regular locations
        let cLocationMarker = new CustomMarker(new google.maps.LatLng(location.Latitude, location.Longitude), self.$refs.mapRef.$mapObject, {
          title: location.Name,
          locationObj: location,
          marker_id: 'l' + location.Id,
          zIndex: markerIcon.locationType === 'msws' ? this.locations.length + i : i,
          markerData: `<img src="${markerIcon.iconImg}" width="${markerIcon.iconSize}" title="${location.Name}" />`
        })

        // Add marker to location marker array
        self.gmarkersLoc.push(cLocationMarker)

        // Marker click event to location detail drawer
        cLocationMarker.addListener('click', function() {
          if (self.iWindow) {
            self.iWindow.close()
          }
          let location = this.args.locationObj

          // Push GA event
          self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'marker-Location', 'PeopleAndLocations | Map')

          // Show location detail
          self.$emit('showSelectedLocation', location.Id)
        })
      })
    },

    /**
     * Add location checkin markers to map
     */
    addLocationCheckinMarkers(locationCheckins = []) {
      let self = this
      let loopCounter = 0
      let maxCheckins = 0
      let nrOfCheckinsLeftOver = 0
      let checkins = []
      let markerContent = ''
      let positionArr = [
        'position: absolute; left: 0px; top:0px;',
        'position: absolute; left: 14px; top:16px;',
        'position: absolute; left: 24px; top:0px;',
        'position: absolute; left: -16px; top:16px;'
      ]

      // Loop  through location checkins
      for (let key in locationCheckins) {
        markerContent = ''
        checkins = locationCheckins[key]
        maxCheckins = checkins.length < 3 ? checkins.length : 3
        nrOfCheckinsLeftOver = checkins.length - maxCheckins
        loopCounter++

        // Build marker content
        for (var i = 0, l = maxCheckins; i < l; i++) {
          let checkin = checkins[i]
          markerContent += `<img width="34" class="checkin" style="${positionArr[i]}" src="${this.$options.filters.getProfileImage(
            checkin.ProfileImage,
            checkin.ProfileId,
            38
          )}" onerror="this.onerror=null;this.src='${require('@/assets/img/defaultProfileImage_v2.png')}';" />`
        }
        if (nrOfCheckinsLeftOver > 0) {
          var leftPos = 36
          if (loopCounter % 2 === 0) {
            leftPos = -20
          }
          markerContent += `<span style="position: absolute; left: ${leftPos}px; top: 16px;" class="color_accent_1 nrOfCheckinsLeftOver">+${nrOfCheckinsLeftOver}</span>`
        }

        // Create marker
        let cMarker = new CustomMarker(new google.maps.LatLng(checkins[0].Latitude, checkins[0].Longitude), self.$refs.mapRef.$mapObject, {
          zIndex: 2000 + checkins.length,
          title: checkins[0].LocationName,
          marker_id: 'lc' + checkins[0].LocationId,
          checkins: checkins,
          markerData: markerContent
        })

        // Add marker to locationCheckin marker array
        this.gmarkersLC.push(cMarker)

        // Marker click event to location detail drawer
        cMarker.addListener('click', function() {
          if (self.iWindow) {
            self.iWindow.close()
          }

          // Push GA event
          self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'marker-Location', 'PeopleAndLocations | Map')

          // Show location detail
          self.$emit('showSelectedLocation', this.args.checkins[0].LocationId)
        })
      }
    },

    /**
     * Add network checkin markers to map
     */
    addNetworkCheckinsMarkers(checkins = []) {
      let self = this

      checkins.map((checkin, i) => {
        let networkCheckinMarker = new CustomMarker(new google.maps.LatLng(checkin.Latitude, checkin.Longitude), self.$refs.mapRef.$mapObject, {
          zIndex: 3000,
          title: checkin.Name,
          marker_id: `c${checkin.Id}`,
          markerData: `<img width="34" class="checkin" src="${this.$options.filters.getProfileImage(
            checkin.ProfileImage,
            checkin.ProfileId,
            38
          )}" onerror="this.onerror=null;this.src='${require('@/assets/img/defaultProfileImage_v2.png')}';" />`
        })

        self.gmarkersNC.push(networkCheckinMarker)

        if (self.isLoggedIn) {
          networkCheckinMarker.addListener('click', function() {
            // Push GA event
            self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'marker-CheckinDetailView', 'PeopleAndLocations | Map')

            // Show public profile details
            self.$emit('showSelectedPublicProfile', {
              profileId: checkin.ProfileId,
              view: 'detail'
            })
          })
        }
      })
    },

    updateIndicator(status) {
    }
  }
}
</script>

<style lang="scss">
.button-filter-drawer {
  margin-left: 10px;
  margin-top: 10px;
}
.custom_marker {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;

  img.checkin {
    display: inline-block;
    border: 2px solid white;
    @include rounded(50%);

    &:first-child {
      margin-left: unset;
    }
  }

  span {
    display: inline-block;
    color: white;
    width: 34px;
    height: 34px;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
    border: 2px solid white;
    @include rounded(50%);
    background-color: $green;
  }
}
</style>

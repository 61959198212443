<template>
  <div>
    <transition name="fade">
      <div
        class="drawer-background"
        v-if="showNetworkCheckinsDrawer"
        @click="hideDrawer"
      ></div>
    </transition>

    <div
      class="drawer right has-background-white"
      :class="{'is-active': showNetworkCheckinsDrawer}"
    >
      <div class="padding header has-text-left">
        <a
          class="close-button"
          @click="hideDrawer"
        >
          <span class="fas fa-times-circle"></span>
          <span>Close</span>
        </a>
      </div>
      <div class="wrapper">
        <div class="has-padding">
          <div
            v-if="checkinFilterSearchString"
            class="title is-5"
          >Based on your search we found the following relevante people</div>
          <checkin-item
            class="has-margin-bottom"
            v-for="checkin in checkins"
            :key="checkin.Id"
            :checkin="checkin"
            :showWorkingon="true"
            :showTags="true"
            :showTime="false"
            :sectionName="sectionName"
          >
          </checkin-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CheckinItem = () => import('@/components/CheckinItem')
export default {
  props: {
    checkins: {
      type: Array,
      default: function() { return [] }
    },
    showNetworkCheckinsDrawer: {
      type: Boolean,
      default: false
    },

    sectionName: {
      type: String,
      default: ''
    }
  },

  components: {
    'checkin-item': CheckinItem
  },

  data() {
    return {
      checkinFilterSearchString: ''
    }
  },

  watch: {
    showNetworkCheckinsDrawer(newval, oldval) {
      this.checkinFilterSearchString = this.$store.getters.getCheckinSearchFilters.searchString
    }
  },

  methods: {
    hideDrawer() {
      this.$emit('showHideNetworkCheckinDrawer')
    }
  }
}
</script>
